export const APP_NAME = 'Latvijas Teniss'

export const CARD_WIDTH = '300px'
export const CARD_WIDTH_SM = '80vw'

export const COURT_LIST = [
  'any',
  'RS korti / Mežaparks',
  'korti.lv / Teika',
  'ENRI / Vaidavas',
  'ENRI / Stirnu',
  'Rīgas volejbola centrs / Skanste',
  'RTU / klusais centrs',
  'Krasta Sporta Centrs'
]
