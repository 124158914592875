import React from 'react'

function CalendarEvent(id, title, start, color) {
  this.id = id
  this.title = title
  this.start = start
  this.color = color
}

export default CalendarEvent
